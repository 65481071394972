import moment from "moment";
import request from "./utils";
import { urls } from "../constants/app";

export function fetchHistoryOrders(phone){
    if(!phone)
        return (dispatch) => {
            dispatch({ type: 'SET_HISTORY_ORDERS_COUNT', count: 0 });
        }
    
    if(phone)
        return async (dispatch, getState) => {
            dispatch({ type: 'SET_LOADER', field: 'historyOrders', value: true });
            const paging = getState().historyOrders.paging;
            const sorting = getState().historyOrders.sorting;
            const date = new Date()

            const dataParams = {
                phone,
                created_at_gte: moment(date).subtract(3, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss')
            };

            const params = {
                phone,
                created_at_gte: moment(date).subtract(3, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                _start: paging.start,
                _limit: paging.limit,
                _sort: `${sorting.field}:${sorting.type}`
            };

            try {
                const countResult = await request({ method: 'get', url: `${urls.orders}/count`, params: dataParams });
                dispatch({ type: 'SET_HISTORY_ORDERS_COUNT', count: countResult });

                const response = await request({ method: 'get', url: urls.orders, params });
                dispatch({ type: 'SET_HISTORY_ORDERS', orders: response });

                dispatch({ type: 'SET_LOADER', field: 'historyOrders', value: false });
            } catch (error) {
                console.error('Ошибка поиска заказов: ', error);
                dispatch({ type: 'SET_LOADER', field: 'historyOrders', value: false });
            }
        }
}

export function clearHistoryOrders(){
    return(dispatch) => {
        dispatch({ type: 'CLEAR_HISTORY_ORDERS' })
    }
}

export function setHistoryOrdersPaging(paging){
    return(dispatch) => {
        dispatch({ type: 'SET_HISTORY_ORDERS_PAGING', paging: paging })
    }
}