import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {fetchUser, updateUser, sendCashBack} from '../../../action/user';
import {fetchCashbackLogs} from '../../../action/logs';
import { fetchOrders, setOrdersPaging } from '../../../action/orders';
import ActionMenu from '../../utils/ActionMenu';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OrdersTable from '../../orders/OrdersTable';
import UserCashbacklogs from '../UserCashbacklogs';
import Portlet from '../../utils/Portlet';
import ReactPaginate from 'react-paginate';
import Input from '../../utils/Input';
import Switch from '../../utils/Switch';
import swal from 'sweetalert';
import { operationType, roleId } from '../../../constants/app';

import Loader from '../../utils/Loader';
import { setUIvisible } from '../../../action/UImobile';
const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}


class AddBonus extends Component {
    state = { value: null };

    componentWillUnmount() {
        this.changeInput(null);
    }

    sendBonus = (balance, operationType) => {
        const cashback = {
            level4: balance
        }
        const userId = this.props.user.id
        sendCashBack(cashback, this.props.user.id, null, operationType).then(result => {
            if (result) {
                swal({
                    title: "Успех",
                    text: "Сообщение отправлено",
                    icon: "success",
                    button: 'OK'
                });

                this.props.fetchUser(userId);
                this.props.fetchCashbackLogs({user: userId});
            }
        });
    }
    
    changeInput = (value) => {
        if(+value < 0) {
            this.setState({ value: 0 })
        } else {
            this.setState({ value: value })
        }
    }

    onSend = () => {
        this.sendBonus(this.state.value, operationType.setBalance)
    }

    onBack = () => {
        this.sendBonus(this.state.value, operationType.minusBalance)
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12">
                    <div className="kt-portlet kt-portlet--height-fluid">
                        <div className="kt-portlet__head" style={(window.innerWidth <= 1480 && window.innerWidth > 1024) ? {padding: '5px'} : {}}>
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Начислить бонусы
                                </h3>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                
                            </div>
                        </div>
                        <div className="kt-portlet__body" style={(window.innerWidth <= 1480 && window.innerWidth > 1024) ? {padding: '5px'} : {}}>
                            <div className="kt-widget4">
                                <div className={`row${window.innerWidth <= 1480 ? ' flex-column' : ''}`}>
                                    <div className="col-xl-4">
                                        <Input 
                                            disableGroup={true}
                                            value={this.state.value}
                                            type="number"
                                            onChange={(value) => this.changeInput(value.target.value)}
                                            isControlledInput={true}
                                        />
                                    </div>
                                    <div className="col-xl-8" style={{ textAlign: 'right' }}>
                                        <button onClick={this.onSend} disabled={!this.state.value || +this.state.value === 0 ? true : false} style={{ marginRight: 4 }} className="btn btn-primary">Начислить</button>
                                        <button onClick={this.onBack} disabled={!this.state.value || +this.state.value === 0 ? true : false} className="btn btn-danger">Списать</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class DetailClient extends Component {
    state = {
        currentPage: 0,
        isArchive: false,
        birthday: null,
    }

    componentWillUnmount() {
        const { pagingOrders: paging } = this.props;
        this.props.setOrdersPaging({ ...paging, start: 0 });
    }

    componentDidMount() {
        this.props.fetchUser(this.props.match.params.id);
        this.props.fetchOrders({ user: this.props.match.params.id }, this.state.isArchive);
        this.props.setUIvisible(false, false)
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">Клиент</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {this.props.user.username}
                            </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <button
                            onClick={() => this.props.history.push('/clients')}
                            className="btn btn-default btn-bold">
                            Назад
                        </button>
                    </div>
                </div>
            </div>
        );
    }


    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setOrdersPaging(updatedPaging);
        this.props.fetchOrders({ user: this.props.match.params.id }, this.state.isArchive);
    }

    handlePageClick = data => {
        const { pagingOrders: paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setOrdersPaging(updatedPaging);
            this.props.fetchOrders({ user: this.props.match.params.id }, this.state.isArchive);
        });
    };

    changeArchive = (value) => {
        this.setState({ isArchive: value });
        this.props.fetchOrders({ user: this.props.match.params.id }, value);
    }

    onChangeBirthday = (e) => {
        this.setState({ birthday: e.target.value })
    }

    updateBirthday = () => {
        const { updateUser } = this.props;
        updateUser(this.props.match.params.id, { birthday: this.state.birthday });
        this.setState({ birthday: null });
    }

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    renderPagination() {
        const { countOrders: count, pagingOrders: paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded order_table">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                    disableInitialCallback={this.props.loadingOrders}
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select disabled={this.props.loadingOrders} onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }


    renderArchiveSwitch() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch disabled={this.props.loadingOrders} onChange={this.changeArchive} nomargin={true} checked={this.state.isArchive}/>
                <span style={{ marginLeft: '10px', fontSize: '14px', marginTop: '-5px' }}>Архив заказов</span>
            </div>
        );
    }

    renderOrders() {
        return (
            <div className="row">
            <div className="col-xl-12">
                <Portlet title="Заказы" fit={true} right={this.renderArchiveSwitch()} style={{ backgroundColor: '#00000000'}}>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" style={{ marginBottom: '0px'}}>
                            {this.props.loadingOrders ?  <div style={styles.loading}><Loader /></div> : (
                                 <OrdersTable isArchive={this.state.isArchive} items={this.props.orders}/>
                            )}
                           
                            {
                                this.props.orders.length > 0 && this.renderPagination()
                            }
                        </div>
                </Portlet>
                </div>
            </div>
        );
    }

    deleteFilial = (idFilial) => {
        const { updateUser, user } = this.props;
        const updatedPoints = user.points.filter(item => item.id !== idFilial).map(item => item.id);
        updateUser(this.props.match.params.id, { points: updatedPoints })
    }

    renderFilials() {
        const { points } = this.props.user;

        return (
            <div className="row">
                <div className="col-xl-12">
                <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Филиалы
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <Link
                                to={{
                                    pathname: `/clients/edit/${this.props.user.id}`,
                                    state: {
                                        step: 2
                                    }
                                }}
                                className="btn btn-label-brand btn-bold btn-sm">
                                Добавить филиал
                            </Link>
                        </div>
                    </div>
                    <div className="kt-portlet__body">
                        <div className="kt-widget4">
                            {
                                points.length ? points.map(item => {
                                    const actions = [
                                        { name: 'Удалить', icon: 'flaticon2-trash', action: () => this.deleteFilial(item.id) }
                                    ];
                                    return (
                                        <div key={item.id} className="kt-widget4__item">
                                            <span className="kt-widget4__icon">
                                                <i className="flaticon-placeholder kt-font-info"></i>
                                            </span>
                                            <Link to={`/handbook/filials/${item.id}`} className="kt-widget4__title kt-widget4__title--light">
                                                {this.renderAdress(item)}
                                            </Link>
                                            
                                                <ActionMenu actions={actions}/>
                                            
                                        </div>
                                    )
                                }) : 'Нет филиалов'
                            }
                        </div>

                    </div>
                </div>
            </div>
            </div>
    
        );
    } 

    renderAdress(item) {
        if (item.privateHouse) {
            return item.street ? `${item.area ? `${item.area}, ` : ''}${item.street}, ${item.house} (Частный дом)` : '-';
        }
        return item.street ? `${item.area ? `${item.area}, ` : ''}${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}` : '-';
    }

    render() {
        const { user } = this.props;

        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }

        if (this.props.error) {
            return (
                <div className="alert alert-outline-danger fade show" role="alert">
                    <div className="alert-icon"><i className="flaticon-questions-circular-button"></i></div>
                    <div className="alert-text">A simple danger alert—check it out!</div>
                    <div className="alert-close">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"><i className="la la-close"></i></span>
                        </button>
                    </div>
                </div>
            );
        }


        return (
            <Fragment>
                {this.renderSubHeader()}
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                        <div className="kt-grid__item kt-app__toggle kt-app__aside" style={{ opacity: 1}}>
                            <div className="kt-portlet kt-portlet--height-fluid-">
                                <div className="kt-portlet__body kt-portlet__body--fit-y" style={(window.innerWidth <= 1480 && window.innerWidth > 1024) ? {padding: '5px'} : {}}>

                                    <div className="kt-widget kt-widget--user-profile-1">
                                        <div className="kt-widget__head">
                                            <div className="kt-widget__content" style={{ paddingLeft: 0 }}>

                                                <div className="kt-widget__section">
                                                    <div className="kt-widget__username">
                                                        <span style={{ marginRight: '5px' }}>{user.name ? user.name : user.username}</span>
                                                        {
                                                            user.confirmed 
                                                                ? <span style={{ verticalAlign: 'middle' }} className="kt-badge kt-badge--brand kt-badge--inline">активен</span>
                                                                : <span style={{ verticalAlign: 'middle' }} className="kt-badge kt-badge--warning kt-badge--inline">не подтвержден</span>
                                                        }
                                                        
                                                    </div>

                                                    <span className="kt-widget__subtitle">
                                                        {user.role.description}
                                                    </span>
                                                </div>
                                                {
                                                    /*
                                                    <div className="kt-widget__action">
                                                        <button type="button" className="btn btn-info btn-sm">chat</button>&nbsp;
                                                        <button type="button" className="btn btn-success btn-sm">follow</button>
                                                    </div>
                                                    */
                                                }
                                            </div>
                                        </div>
                                        <div className="kt-widget__body">
                                            <div className="kt-widget__content">
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Баланс:</span>
                                                    <span className="kt-widget__data">{user.balance} бонусов</span>
                                                </div>
                                                {
                                                    user.code && (
                                                        <div className="kt-widget__info">
                                                            <span className="kt-widget__label">Персональный код:</span>
                                                            <span className="kt-widget__data">{user.code}</span>
                                                        </div>
                                                    )
                                                }
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Дата регистрации:</span>
                                                    <span className="kt-widget__data">{moment(user.created_at).format('DD.MM.YYYY HH:mm')}</span>
                                                </div>
                                                { 
                                                    user.role.id !== roleId.client && (
                                                        <div className="kt-widget__info">
                                                            <span className="kt-widget__label">Email:</span>
                                                            <span className="kt-widget__data">{user.email}</span>
                                                        </div>
                                                    )
                                                }
                                               
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Телефон:</span>
                                                    <span className="kt-widget__data">{user.phone ? `+${user.phone}` : '-'}</span>
                                                </div>

                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">День рождения:</span>
                                                    {this.props.currentUser.role.id === roleId.admin 
                                                    ? 
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        <div className='form-group' style={{ margin: 0 }}>
                                                            <input
                                                            className='form-control'
                                                            type='date'
                                                            defaultValue={user.birthday ? moment(user.birthday).format('YYYY-MM-DD') : null}
                                                            onChange={this.onChangeBirthday}
                                                            />
                                                        </div>
                                                        {this.state.birthday &&
                                                            <button
                                                            className='btn btn-primary btn-icon'
                                                            style={{ height: '2.5rem', width: '2.5rem' }}
                                                            onClick={this.updateBirthday}
                                                            >
                                                                <i className='flaticon2-check-mark' />
                                                            </button>
                                                        }
                                                    </div>
                                                    : <span className="kt-widget__data">{user.birthday ? moment(user.birthday).format('DD.MM.YYYY'): '-'}</span>
                                                    }
                                                </div>
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Город:</span>
                                                    <span className="kt-widget__data">{user.city ? user.city.name : '-'}</span>
                                                </div>
                                                { 
                                                    user.role.id === roleId.client && (
                                                        <div className="kt-widget__info">
                                                            <span className="kt-widget__label">Адрес доставки:</span>
                                                            <div className="kt-widget__data">{this.renderAdress(user)}</div>
                                                        </div>
                                                    )
                                                }
                                                { 
                                                    user.role.id === roleId.client && (
                                                        <div className="kt-widget__info">
                                                            <span className="kt-widget__label">Пункт самовывоза:</span>
                                                            <div className="kt-widget__data">{user.points[0] ? this.renderAdress(user.points[0]) : '-'}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {
                                                (this.props.currentUser.role.id === roleId.admin || this.props.currentUser.role.id === roleId.chiefMarketingSpecialist) && (
                                                    <div className="kt-widget__items">
                                                        <Link
                                                            to={{
                                                                pathname: `/clients/edit/${this.props.user.id}`,
                                                            }}
                                                            className="btn btn-outline-brand">
                                                            Редактировать профиль
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {(this.props.currentUser.role.id === roleId.admin || this.props.currentUser.role.id === roleId.chiefMarketingSpecialist) && (
                                <>
                                    <AddBonus 
                                    updateUser={this.props.updateUser} 
                                    user={this.props.user} 
                                    fetchUser={this.props.fetchUser}
                                    fetchOrders={this.props.fetchOrders}
                                    fetchCashbackLogs={this.props.fetchCashbackLogs}
                                    />
                                    {window.innerWidth > 1024 && <UserCashbacklogs />}
                                </>
                                )
                            }
                        </div>

                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            {
                               (user.role.id === roleId.manager || user.role.id === roleId.regionalDirector) ? this.renderFilials() : this.renderOrders()
                            }
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.users.detail,
    loading: state.loaders.user || state.loaders.updateUser,
    currentUser: state.user.info,
    orders: state.orders.list,
    loadingOrders: state.loaders.orders,
    countOrders: state.orders.count,
    pagingOrders: state.orders.paging,
    error: state.errors.user
});

const mapDispatchToProps = {
    fetchUser: (id) => fetchUser(id),
    updateUser: (id, params) => updateUser(id, params),
    fetchOrders: (data, isArchive) => fetchOrders(data, isArchive),
    setOrdersPaging: (paging) => setOrdersPaging(paging),
    fetchCashbackLogs: (data) => fetchCashbackLogs(data),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailClient);