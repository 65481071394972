import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../constants/app";
import request from "../../action/utils";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const paymentTitle = {
    cash: 'Наличные',
    terminal: 'Терминал',
    online: 'Онлайн-оплата',
    paymented: 'Оплачен'
};

const сashTitle = {
    no: 'под расчет',
    c1000: 'сдача с 1000',
    c1500: 'сдача с 1500',
    c2000: 'сдача с 2000',
    c5000: 'сдача с 5000',
};

const SaveNewOrderButton = ({editOrder, editedOrder}) => {
    const dispatch = useDispatch();
    const stateNewOrders = useSelector(state => state.neworders);
    const userInfo = useSelector(state => state.user.info);
    const settings = useSelector(state => state.settings.init.categories)

    const renderAddress = (order) => {
        return `${order.area ? order.area : ''}${order.street ? ', ' + order.street : ''}${order.house ? ', ' + order.house : ''}${order.entrance ? `, подъезд ${order.entrance}` : ''}${order.floor ? `, этаж ${order.floor}` : ''}${order.room ? `, кв./оф. ${order.room}` : ''}`;
    }

    const saveChangeLog = () => {
        const date = new Date();
        const order = stateNewOrders;        
        let changeLog = {};

        if(renderAddress(order) !== renderAddress(editedOrder)){
            changeLog = {
                ...changeLog,
                address: `Адрес изменен с ${renderAddress(editedOrder)} на ${renderAddress(order)}`
            }
        }

        if(order.phone !== editedOrder.phone){
            changeLog = {
                ...changeLog,
                phone: `Номер телефона изменен с ${editedOrder.phone} на ${order.phone}`
            }
        }

        if(order.bonusCount !== editedOrder.bonusCount){
            changeLog = {
                ...changeLog,
                bonusCount: `Кол-во бонусов изменено с ${editedOrder.bonusCount} на ${order.bonusCount}`
            }
        }

        if(order.changeCash !== editedOrder.changeCash){
            changeLog = {
                ...changeLog,
                changeCash: `Сдача изменена с ${сashTitle[editedOrder.changeCash]} на ${сashTitle[order.changeCash]}`
            }
        }

        if(order.payment !== editedOrder.payment){
            changeLog = {
                ...changeLog,
                payment: `Способ оплаты изменен с ${paymentTitle[editedOrder.payment]} на ${paymentTitle[order.payment]}`
            }
        }

        if(order.city && order.city.id !== editedOrder.city.id){
            changeLog = {
                ...changeLog,
                city: `Город изменен с ${editedOrder.city.name} на ${order.city.name}`
            }
        }

        if(order.point && order.point.id !== editedOrder.point.id){
            const renderLogAdress = (city, point) => {
                if(!city && !point)
                    return 'Не определен'
                return `${city ? city.name : point ? point.city.name : 'Город не определен'}${point ? `, ${point.street} ${point.house}` : ', филиал не определен'}`
            }
            changeLog = {
                ...changeLog,
                point: `Филиал изменен с ${renderLogAdress(editedOrder.city, editedOrder.point)} на ${renderLogAdress(order.city, order.point)}`
            }
        }

        if(order.zone && order.zone.point){
            let point = null

            if(!order.zone.point.id) {
                point = this.props.filials.find((el) => el.id === order.zone.point)
            } else {
                point = order.zone.point
            }

            if(point && point.id !== editedOrder.point.id) {
                const renderLogAdress = (city, point) => {
                    if(!city && !point)
                        return 'Не определен'
                    return `${city ? city.name : point ? point.city.name : 'Город не определен'}${point ? `, ${point.street} ${point.house}` : ', филиал не определен'}`
                }
                changeLog = {
                    ...changeLog,
                    point: `Филиал изменен с ${renderLogAdress(editedOrder.city, editedOrder.point)} на ${renderLogAdress(order.city, point)}`
                }
            }
        }

        if(order.comment !== editedOrder.comment){
            changeLog = {
                ...changeLog,
                comment: `Комментарий изменен с ${editedOrder.comment} на ${order.comment}`
            }
        }

        if(order.deliveryMethod !== editedOrder.deliveryMethod){
            changeLog = {
                ...changeLog,
                deliveryMethod: `Тип заказа изменен с ${editedOrder.deliveryMethod === 'own' ? 'Вынос' : 'Доставка'} на ${order.deliveryMethod === 'own' ? 'Вынос' : 'Доставка'}`
            }
        }

        if(order.deliveryPrice !== editedOrder.deliveryPrice){
            changeLog = {
                ...changeLog,
                deliveryPrice: `Стоимость доставки изменена с ${editedOrder.deliveryPrice} на ${order.deliveryPrice}`
            }
        }

        if(order.deliveryTime !== editedOrder.deliveryTime){
            changeLog = {
                ...changeLog,
                deliveryTime: `Время доставки изменено с ${editedOrder.deliveryPrice} на ${order.deliveryPrice}`
            }
        }

        if(order.isAggPrice !== editedOrder.isAggPrice){
            if(order.isAggPrice) {
                changeLog = {
                    ...changeLog,
                    isAggPrice: `Цены изменены на цены аггрегаторов`
                }
            } else {
                changeLog = {
                    ...changeLog,
                    isAggPrice: `Цены изменены на обычные`
                }
            }
        }

        if(order.promocode !== editedOrder.promocode){
            if(order.promocode) {
                changeLog = {
                    ...changeLog,
                    promocode: `Применен промокод ${order.promocode.name}`
                }
            } else {
                changeLog = {
                    ...changeLog,
                    promocode: `Примененный ранее промокод удален из заказа`
                }
            }
        }

        if(parseFloat(order.salePrice) !== parseFloat(editedOrder.salePrice)){
            changeLog = {
                ...changeLog,
                salePrice: `Скидка изменена с ${editedOrder.salePrice} на ${order.salePrice}`
            }
        }

        if(order.visible_datetime !== editedOrder.visible_datetime){
            changeLog = {
                ...changeLog,
                visible_datetime: `Отображаемое время заказа изменено с ${editedOrder.visible_datetime ? moment(editedOrder.visible_datetime).format('HH:mm DD.MM.YYYY') : moment(editedOrder.created_at).format('HH:mm DD.MM.YYYY')} на ${moment(order.visible_datetime, 'DD.MM.YYYY HH:mm').format('HH:mm DD.MM.YYYY')}`
            }
        }

        if(order.unit !== editedOrder.unit){
            changeLog = {
                ...changeLog,
                unit: `Изменено кол-во приборов с ${editedOrder.unit} на ${order.unit}`
            }
        }

        order.goods.map((item) => {
            let name = 'NaN';

            if(item.wokData) {
                name = item.wokData.main.title;
            } else {
                if(item.name) 
                    name = item.name;
                
                if(item.data && item.data.title)
                    name = item.data.title;                
            }

            if(item.addCount !== item.count && item.changeMod){
                changeLog = {
                    ...changeLog,
                    changeMod: {
                        ...(changeLog.addGood || {}),
                        [item.id]: `Изменен размер ${name} с ${item.isMod ? '30см' : '36см'} на ${item.isMod ? '36см' : '30см'}`
                    }
                }
            }

            if(!item.wokData && item.addCount && item.addCount !== 0) {
                if(item.addCount === item.count) {                                                
                    changeLog = {
                        ...changeLog,
                        addGood: {
                            ...(changeLog.addGood || {}),
                            [item.id]: `Добавлена позиция ${name}${(item.category === settings.pizza || (item.categoryName && item.categoryName.toLowerCase() === 'пицца')) ? item.isMod ? ' 36см' : ' 30см' : ''} кол-во: ${Math.abs(item.addCount)}`
                        }
                    }
                } else {
                    if(item.addCount > 0) {
                        changeLog = {
                            ...changeLog,
                            editCount: {
                                ...(changeLog.editCount || {}),
                                [item.id]: `Увеличено кол-во ${name}${(item.category === settings.pizza || (item.categoryName && item.categoryName.toLowerCase() === 'пицца')) ? item.isMod ? ' 36см' : ' 30см' : ''} на ${Math.abs(item.addCount)}`
                            }
                        }
                    } else {
                        changeLog = {
                            ...changeLog,
                            editCount: {
                                ...(changeLog.editCount || {}),
                                [item.id]: `Уменьшено кол-во ${name}${(item.category === settings.pizza || (item.categoryName && item.categoryName.toLowerCase() === 'пицца')) ? item.isMod ? ' 36см' : ' 30см' : ''} на ${Math.abs(item.addCount)}`
                            }
                        }
                    }
                }
            }

            if(item.wokData) {
                let oldMeat = null
                let oldTopping = null
                let oldSouce = null
                let newMeat = null
                let newTopping = null
                let newSouce = null

                if(item.wokData.meat) 
                    Object.values(item.wokData.meat).map((el) => {                            
                        oldMeat = `${oldMeat ? oldMeat + ', ' : ''}${el.addCount !== el.count ? `${el.data.title} x${el.count - (el.addCount || 0)}` : ''}`
                        newMeat = `${newMeat ? newMeat + ', ' : ''}${el.data.title} x${el.count}`
                        return el                            
                })

                if(item.wokData.topping) 
                    Object.values(item.wokData.topping ).map((el) => {
                        oldTopping = `${oldTopping ? oldTopping + ', ' : ''}${el.addCount !== el.count ? `${el.data.title} x${el.count - (el.addCount || 0)}` : ''}`
                        newTopping = `${newTopping ? newTopping + ', ' : ''}${el.data.title} x${el.count}`
                        return el
                })

                if(item.wokData.souce) 
                    Object.values(item.wokData.souce).map((el) => {
                        oldSouce = `${oldSouce ? oldSouce + ', ' : ''}${el.addCount !== el.count ? `${el.title} x${el.count - (el.addCount || 0)}` : ''}`
                        newSouce = `${newSouce ? newSouce + ', ' : ''}${el.title} x${el.count}`
                        return el
                })

                const oldIngredientsWok = `${name} x${item.count - (item.addCount || 0)}${oldMeat ? `, мясо: ${oldMeat}` : ''}${oldTopping ? `, топпинги: ${oldTopping}` : ''}${oldSouce ? `, соусы: ${oldSouce}` : ''}`
                const newIngredientsWok = `${name} x${item.count}${newMeat ? `, мясо: ${newMeat}` : ''}${newTopping ? `, топпинги: ${newTopping}` : ''}${newSouce ? `, соусы: ${newSouce}` : ''}`

                const chekId = item.wokData.id || item.id
                if(item.count - (item.addCount || 0) === 0){
                    changeLog = {
                        ...changeLog,
                        addWok: {
                            ...(changeLog.editWok || {}),
                            [chekId]: `Добавлен ВОК ${newIngredientsWok}`
                        }
                    }
                } else {
                    if(newIngredientsWok !== oldIngredientsWok){
                        changeLog = {
                            ...changeLog,
                            editWok: {
                                ...(changeLog.editWok || {}),
                                [chekId]: `ВОК ${oldIngredientsWok} изменен состав на ${newIngredientsWok}`
                            }
                        }
                    }
                }
            }
            return item
        })

        if(order.removedGoods.filter(removedPosition => removedPosition.addCount !== removedPosition.count).length > 0){
            const logRemovedGoods = order.removedGoods.map((removedPosition) => {
                if(removedPosition.addCount !== removedPosition.count) {
                    if(!removedPosition.wokData) {
                        return(`${removedPosition.name ? removedPosition.name : removedPosition.data.title}${(removedPosition.category === settings.pizza || (removedPosition.categoryName && removedPosition.categoryName.toLowerCase() === 'пицца')) ? removedPosition.isMod ? ' 36см' : ' 30см' : ''} x${removedPosition.count}`)
                    } else {
                        let meat = null
                        let topping = null
                        let souce = null
                        if(removedPosition.wokData.meat) 
                            Object.values(removedPosition.wokData.meat).map((el) => {                            
                                meat = `${meat ? meat + ', ' : ''}${el.data.title} x${el.count}`
                                return el                            
                        })
        
                        if(removedPosition.wokData.topping) 
                            Object.values(removedPosition.wokData.meat).map((el) => {
                                topping = `${topping ? topping + ', ' : ''}${el.data.title} x${el.count}`
                                return el
                        })
        
                        if(removedPosition.wokData.souce) 
                            Object.values(removedPosition.wokData.souce).map((el) => {
                                souce = `${souce ? souce + ', ' : ''}${el.addCount !== el.count ? `${el.title} x${el.count - (el.addCount || 0)}` : ''}`
                                return el
                        })

                        return(`${removedPosition.wokData.main.title} x${removedPosition.count}${meat ? `, мясо: ${meat}` : ''}${topping ? `, топпинги: ${topping}` : ''}${souce ? `, соусы: ${souce}` : ''}`)
                    }
                }
                return removedPosition
            })

            const logRemovedGoodsName = 'removedGoods_' + moment(date).format('DD.MM.YYYY HH:mm:ss')
            if(logRemovedGoods.length > 1) {
                changeLog = {
                    ...changeLog,
                    removedPosition: {
                        ...(changeLog.removedPosition || {}),
                        [logRemovedGoodsName]: `Удалены позиции: ${logRemovedGoods.join(', ')}`
                    }
                }
            } else {
                changeLog = {
                    ...changeLog,
                    removedPosition: {
                        ...(changeLog.removedPosition || {}),
                        [logRemovedGoodsName]: `Удалена позиция: ${logRemovedGoods.join(', ')}`
                    }
                }
            }
        }

        let checkChangeLog = {};
        let log = order.changeLog;

        if(log) {
            if(typeof log === 'string') {
                checkChangeLog = JSON.parse(log);
            } else {
                checkChangeLog = log;
            }
        }
        
        const logName = String(userInfo.username + '_' + moment(date).format('DD.MM.YYYY') + '_' + (checkChangeLog ? Object.keys(checkChangeLog).length : 0));

        checkChangeLog[logName] = {
            user: userInfo.username,
            date: moment(date).format('DD.MM.YYYY HH:mm'),
            ...changeLog
        }


        return checkChangeLog
    }

    const handleClickSave = () => {    
        let changeLog = null
        if(editOrder)
            changeLog = saveChangeLog();

        dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: true });
        const order = createNewOrder(changeLog);
        if(window.location.pathname.includes('/edit')) {
            if(stateNewOrders.id) {
                let url = urls.orders
                if(window.location.pathname.includes('/archive'))
                    url = urls.archive
                return request({ method: 'put', url: `${url}/${stateNewOrders.id}`, data: order })
                .then(() => {
                    dispatch({ type: 'CLEAR_NEW_ORDER' });
                    dispatch({ type: 'SET_ORDER', order: null })
                    window.location.href = '/orders';
                    return true;
                })
                .catch(er => {
                    alert('Ошибка ' + er.status);
                    console.log(er);
                    return false;
                })
                .finally(() => {
                    dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: false });
                });
            } else {
                alert('Ошибка не найден id заказа')
            }
        } else {
            return request({ method: 'post', url: urls.orders, data: order })
            .then(() => {
                dispatch({ type: 'CLEAR_NEW_ORDER' });
                dispatch({ type: 'SET_ORDER', order: null })
                window.location.href = '/orders';
            })
            .catch((er) => {        
                alert('Ошибка ' + er.status);
                console.log(er);
            })
            .finally(() => {
                dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: false });
            })
        }
    }
    
    const createNewOrder = (changeLog) => {    
        let checkChangeCash = stateNewOrders.changeCash
        let checkComment = String(stateNewOrders.comment)
        if (stateNewOrders.changeCash && !['no', 'c1000', 'c1500', 'c2000', 'c5000'].includes(stateNewOrders.changeCash))
        {
            checkChangeCash = null
            checkComment = checkComment + String(stateNewOrders.changeCash ? ' | сдача с '+ stateNewOrders.changeCash.slice(1) : '')
        }
    
        const checkGoods = stateNewOrders.goods.map((item) => {
            if(!item.wokData)
                return {
                    id: item.id,
                    article: item.article,
                    isMod: item.isMod ? !item.isMod.default : false,
                    count: item.count,
                    isWok: false,
                    wokData: null,
                    price: stateNewOrders.isAggPrice ? (item.oldPrice ? item.oldPrice : item.price) : item.price,
                    category: item.category.id ? item.category.id : item.category,
                    categoryName: item.categoryName ? item.categoryName : item.category.title ? item.category.title : null,
                    data: {
                        title: (item.data && item.data.title)? item.data.title : item.name ? item.name : null,
                        weight: (item.data && item.data.weight) ? item.data.weight : item.weight ? item.weight : 0,
                    },
                }
    
            const hash = (() => {
                const check = {
                    [item.id]: 1,
                };
                if(item.wokData.souce)
                Object.values(item.wokData.souce).forEach((item) => {
                    check[item.id] = 1;
                });
                if (item.wokData.meat) {
                Object.values(item.wokData.meat).forEach((item) => {
                    check[item.id] = item.count;
                    });
                }
                if (item.wokData.topping) {
                Object.values(item.wokData.topping).forEach((item) => {
                    check[item.id] = item.count;
                    });
                }
                const uuid = uuidv4(JSON.stringify(check));
                return uuid;
                });
                
            const WokDataCheck = {
                ...item.wokData,
                id: hash(),
                price: stateNewOrders.isAggPrice ? (item.wokData.oldPrice ? item.wokData.oldPrice : item.wokData.price) : item.wokData.price,
                meat: Object.keys(item.wokData.meat).reduce((acc, key) => {
                    const meatItem = item.wokData.meat[key];
                    acc[key] = {
                      count: meatItem.count,
                      article: meatItem.article,
                      data: meatItem.data
                    };
                    return acc;
                }, {}),
                topping: Object.keys(item.wokData.topping).reduce((acc, key) => {
                    const toppingItem = item.wokData.topping[key];
                    acc[key] = {
                      count: toppingItem.count,
                      article: toppingItem.article,
                      data: toppingItem.data
                    };
                    return acc;
                }, {}),
                souce: Object.keys(item.wokData.souce).reduce((acc, key) => {
                    const souceItem = item.wokData.souce[key];
                    acc[key] = {
                      id: souceItem.id,
                      article: souceItem.article,
                      title: souceItem.title,
                      count: souceItem.count,
                      price: souceItem.price,
                      oldPrice: souceItem.oldPrice
                    };
                    return acc;
                }, {})              
            }
    
            return {
                id: hash,
                isMod: false,
                count: item.count,
                isWok: true,
                wokData: WokDataCheck 
            }
        }) 
    
        const newOrder = {
            deliveryMethod: stateNewOrders.deliveryMethod,
            point: stateNewOrders.point.id,
            city: stateNewOrders.point.city.id,        
            phone: String(stateNewOrders.phone),
            comment: checkComment,
            timeMethod: stateNewOrders.timeMethod ? stateNewOrders.timeMethod : 'fast',
            deliveryPrice: stateNewOrders.deliveryPrice,
            deliveryArticle: stateNewOrders.deliveryArticle,
            deliveryTime: stateNewOrders.deliveryTime ? stateNewOrders.deliveryTime : null,
            deliveryTimeComment: stateNewOrders.deliveryTimeComment ? stateNewOrders.deliveryTimeComment : null,
            totalPrice: parseFloat(stateNewOrders.totalPrice),
            goodsPrice: parseFloat(stateNewOrders.goodPrice),
            salePrice: parseFloat(stateNewOrders.salePrice),
            unit: parseInt(stateNewOrders.unit) ? parseInt(stateNewOrders.unit) : null,
            goods: checkGoods,
            payment: stateNewOrders.payment,
            changeCash: checkChangeCash,
            newApi: true,
            isSite: stateNewOrders.isSite ? stateNewOrders.isSite : false,
            isAggPrice: stateNewOrders.isAggPrice,
            bonusCount: stateNewOrders.bonusCount ? stateNewOrders.bonusCount : 0,
            zone: stateNewOrders.point ? null : stateNewOrders.zone ? stateNewOrders.zone : null,
            user: stateNewOrders.user ? stateNewOrders.user : null,
            username: stateNewOrders.username ? stateNewOrders.username : null,
            source: stateNewOrders.source ? stateNewOrders.source : 'crm',
            visible_datetime: stateNewOrders.visible_datetime,
            changeLog: changeLog,
        }

        if(!window.location.pathname.includes('/edit')) {
            newOrder.authorRole = userInfo.role.id;
            newOrder.author = userInfo.username;
        }
    
        if(stateNewOrders.deliveryMethod === 'delivery')
        {
            newOrder.street = stateNewOrders.street;
            newOrder.house = stateNewOrders.house;
            newOrder.room = stateNewOrders.room;
            newOrder.entrance = stateNewOrders.entrance;
            newOrder.floor = stateNewOrders.floor;
        }
    
        return newOrder;
    }

    return (
        <button className="order_position_list_footer_button save" onClick={handleClickSave}>Сохранить</button>
    );
}

export default SaveNewOrderButton;


