const initialState = {
    singIn: false,
    global: true,
    users: false,
    user: true,
    updateUser: false,
    removeUserPoint: false,
    register: false,
    cities: true,
    addCity: false,
    addFilial: false,
    addZone: false,
    city: true,
    zone: true,
    zones: true,
    editCity: false,
    editZone: false,
    orders: false,
    ordersOfHead: false,
    updateorder: false,
    settings: true,
    promocodes: false,
    categories: true,
    chanels: true,
    message: false,
    news: true,
    addNews: false,
    goods: true,
    sliders: true,
    addSlider: false,
    updateSlider: null,
    deleteSlider: null,
    yookassa: true,
    addKassa: false,
    updateKassa: null,
    deleteKassa: null,
    category: true,
    modalCategory: false,
    modalGood: false,
    updateGood: null,
    deleteGood: null,
    detailGood: true,
    modalRegionPrice: false,
    updateRegionPrice: null,
    deleteRegionPrice: null,
    modalModification: false,
    updateModification: null,
    deleteModification: null,
    cashbackLogsUser: false,
    ips: true,
    updateIps: null,
    deleteIps: null,
    phonecodes: true,
    promotions: true,
    loadingFilialsNewOrder: false,
    loadingSaveOrder: false,
    payment: true,
    addPayment: false,
    editPayment: false,
    historyOrders: false
};

export default function loaders(state = initialState, action) {
  switch(action.type) {
      case 'SET_LOADER': {
          return {
              ...state,
              [action.field]: action.value
          };
      }
    default: return state;
  }
}