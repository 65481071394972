import React from 'react';
import { useSelector } from 'react-redux';
import { modalsSelector } from '../../selectors';
import { CategoryModal } from './CategoryModal';
import GoodModal from './GoodModal';
import { ModificationModal } from './ModificationModal';
import { RegionPriceModal } from './RegionPriceModal';
import SliderModal from './SliderModal';
import StopList from './StopList';
import YooKassaModal from './YooKassaModal';
import SearchOrderModal from './SearchOrderModal';
import ChangeLogOrderModal from './ChangeLogOrderModal';
import HistoryOrdersModal from './HistoryOrdersModal';


const modals = {
  category: <CategoryModal key="category_modal" />,
  good: <GoodModal key="good_modal" />,
  stopList: <StopList key="stopListModal" />,
  regionPrice: <RegionPriceModal key="regionPriceModal" />,
  modification: <ModificationModal key="modificationModal" />,
  slider: <SliderModal key="sliderModal" />,
  yookassa: <YooKassaModal key="kassaModal" />,
  orderSearch: <SearchOrderModal key='searchOrderModal'/>,
  changeLogOrder: <ChangeLogOrderModal key='changeLogOrder'/>,
  historyOrders: <HistoryOrdersModal key='historyOrders'/>
};

export function Modals() {
  const stateModals = useSelector(modalsSelector);

  if (Object.keys(stateModals).some((key) => stateModals[key])) {
    return (
      <>
        {Object.keys(modals).filter((key) => stateModals[key]).map((key) => modals[key])}
        <div className="modal-backdrop fade show" />
      </>
    );
  }

  return null;
}
