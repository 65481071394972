import React, { Component } from 'react';
import moment from 'moment';
import { deliveryType, promotionType } from './constants';

class Detail extends Component {
    componentWillUnmount() {
        this.props.setDetailPromotion(null);
    }

    goBack = () => {
        this.props.setDetailPromotion(null);
    }

    onEdit = () => {
        this.props.setEditedPromotion(this.props.detail);
    }

    renderCities = (cities) => {
        return cities.map(item => item.name).join(', ');
    }

    render() {
        const { detail } = this.props;

        return (
            <div className="kt-grid  kt-wizard-v1 kt-wizard-v1--white" id="kt_apps_projects_add" data-ktwizard-state="first">
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                    <form className="kt-form">
                        <div className="kt-wizard-v1__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                            <div className="kt-heading kt-heading--md">Подробная информация</div>
                            <div className="kt-section kt-section--first">
                                <div className="kt-wizard-v1__form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="kt-section__body">
                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Название</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        { detail.name }
                                                    </div>
                                                </div>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Включен</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        {detail.active ? 'Да' : 'Нет'}
                                                    </div>
                                                </div>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Город</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        { this.renderCities(detail.cities) || 'Любой' }
                                                    </div>
                                                </div>

                                                <hr className="hrLine"/>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Тип</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        { promotionType[detail.type] }
                                                    </div>
                                                </div>

                                                {
                                                    detail.type === 'goods' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                {
                                                                    detail.goods && detail.goods.length > 0 && (
                                                                        <div style={{ marginTop: 15 }}>
                                                                            {
                                                                                detail.goods.map((good, index) => (
                                                                                    <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                                                                        <div className="col-xl-3 col-lg-3">{good.label}</div>
                                                                                        <div className="col-lg-9 col-xl-9">
                                                                                           - скидка {good.sale}{good.persent ? '%' : ' руб.'}
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    detail.type === 'category' && (
                                                        <div className="form-group alItC row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Категория</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                               {detail.category && detail.category.label}
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    detail.type !== 'goods' && (
                                                        <div className="form-group alItC row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Скидка</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                {detail.value}{detail.persent ? '%' : ' руб.'}
                                                            </div>
                                                        </div>
                                                    )
                                                }

{
                                                    detail.type === 'addGoods' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                {
                                                                    detail.goods && detail.goods.length > 0 && (
                                                                        <div style={{ marginTop: 15 }}>
                                                                            {
                                                                                detail.goods.map((good) => (
                                                                                    <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                                                                        <div className="col-xl-3 col-lg-3">{good.label}</div>
                                                                                        <div className="col-lg-9 col-xl-9">
                                                                                          {`- цена ${good.sale} руб. (Артикул: ${good.article})`}
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <hr className="hrLine"/>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Период действия</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        {
                                                            !detail.startDate && !detail.endDate && 'Без ограничений'
                                                        }
                                                        {`${detail.startDate ? `с ${moment(detail.startDate).format('DD.MM.YYYY HH:ss')}` : ''}${detail.endDate ? ` по ${moment(detail.endDate).format('DD.MM.YYYY HH:ss')}` : ''}` }
                                                    </div>
                                                </div>
                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Часы действия</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        {
                                                            !detail.startTimeDate && !detail.endTimeDate && 'Без ограничений'
                                                        }
                                                        {`${detail.startTimeDate ? `с ${moment(detail.startTimeDate).format('HH:ss')}` : ''}${detail.endTimeDate ? ` по ${moment(detail.endTimeDate).format('HH:ss')}` : ''}` }
                                                    </div>
                                                </div>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Сумма заказа</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        {detail.minPrice} руб.
                                                    </div>
                                                </div>

                                                <div className="form-group alItC row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Предыдущий заказ</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        {detail.prevOrderDays ? `${detail.prevOrderDays} дн.` : '-'}
                                                    </div>
                                                </div>

                                                <div className="form-group alItC form-group-last row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Тип доставки</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                       { deliveryType[detail.delivery] }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-form__actions">
                            <button onClick={this.goBack} style={{ marginRight: 10 }} className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                                Назад
                            </button>
                            <button onClick={this.onEdit} className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                                Редактировать
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Detail;