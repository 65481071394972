import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import ActionMenu from '../utils/ActionMenu';
import swal from 'sweetalert';
import { deliveryType, promotionType } from './constants';

class List extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell promotions_name">
                        <span>Название</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_status">
                        <span>Включен</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_city">
                        <span>Город(а)</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_type">
                        <span>Тип</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_sum">
                        <span>Сумма заказа</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_type">
                        <span>Тип доставки</span>
                    </th>
                    <th className="kt-datatable__cell promocodes_action">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.setDetailPromotion(item);
    }
    
    setEditedPromotion = (promotion) => {
        this.props.setEditedPromotion(promotion);
    }

    deletePromotion = (item) => {
        swal({
            title: "Удаление акции",
            text: "Вы действительно хотите удалить акцию?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deletePromotion(item.id).then(result => {
                    if (result) {
                        this.props.fetchPromotions();
                    }
                });
            }
        });
    }

    renderCities = (cities) => {
        return cities.map(item => item.name).join(', ');
    }

    renderBody() {
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.setDetailPromotion(item) },
                            { name: 'Редактирование', icon: 'flaticon2-contract', action:  () => this.setEditedPromotion(item) },
                            { name: 'Удалить', icon: 'flaticon2-trash', action:  () => this.deletePromotion(item) }
                        ];
                        return (
                            <tr
                                title="Двойной клик для подробнее..."
                                onDoubleClick={this.handleDoubleClick(item)}
                                key={item.id}
                                className="kt-datatable__row"
                            >
                                <td className="kt-datatable__cell promotions_name">
                                    <span>{item.name}</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_status">
                                    <span style={item.active ? { color: '#0abb87' } : { color: '#fd397a' }}>{item.active ? 'Да' : 'Нет'}</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_city">
                                    <span>{this.renderCities(item.cities) || 'Любой'}</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_type">
                                    <span>{promotionType[item.type]}</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_sum">
                                    <span>{item.minPrice} руб.</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_type">
                                    <span>{deliveryType[item.delivery]}</span>
                                </td>
                                <td className="kt-datatable__cell promocodes_action">
                                    <ActionMenu actions={actions}/>
                                </td>
                            </tr>
                        );
                    }) 
                }
            </tbody>
        );
    }

    renderBodyMobie() {
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.setDetailPromotion(item) },
                            { name: 'Редактирование', icon: 'flaticon2-contract', action:  () => this.setEditedPromotion(item) },
                            { name: 'Удалить', icon: 'flaticon2-trash', action:  () => this.deletePromotion(item) }
                        ];
                        return (
                            <tr
                                title="Двойной клик для подробнее..."
                                onDoubleClick={this.handleDoubleClick(item)}
                                key={item.id}
                                className="kt-datatable__row"
                            >
                                <div style={{display: 'flex', gap: '10px', flexDirection: 'column', padding: '10px'}}>
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                        <div style={window.innerWidth > 400 ? { width: '50%' } : { width: '70%' }}>
                                            <span>Промокод: <span style={{ color: '#000' }}>{item.name}</span></span>
                                        </div>
                                        <div style={window.innerWidth > 400 ? { width: '50%' } : { width: '30%' }}>
                                           <span>Активен: <span style={item.active ? { color: '#0abb87' } : { color: '#fd397a' }}>{item.active ? 'Да' : 'Нет'}</span></span>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                                        <span>Город(а): <span style={{ color: '#000' }}>{this.renderCities(item.cities) || 'Любой'}</span></span>
                                    </div>
                                    <div style={window.innerWidth > 400 ? { width: '100%', display: 'flex' } : { width: '100%', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                        <div style={window.innerWidth > 400 ? { width: '50%' } : { width: '100%' }}>
                                            <span>Тип скидки: <span style={{ color: '#000' }}>{promotionType[item.type]}</span></span>
                                        </div>
                                        <div style={window.innerWidth > 400 ? { width: '50%' } : { width: '100%' }}>
                                            <span>Сумма заказа от: <span style={{ color: '#000' }}>{item.minPrice} руб.</span></span>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex'}}>
                                        <div style={{ width: '100%' }}>
                                            <span>Тип доставки: <span style={{ color: '#000' }}>{deliveryType[item.delivery]}</span></span>
                                        </div>                                        
                                    </div>
                                    <div style={{ width: 'calc(100% + 50px)', display: 'flex', justifyContent: 'flex-end' }}>
                                        <ActionMenu actions={actions}/>
                                    </div>
                                </div>
                                
                            </tr>
                        );
                    }) 
                }
            </tbody>
        );
    }

    render() { 
        if(window.innerWidth > 900)
            return (
                <table className="kt-datatable__table" style={{ overflow: 'inherit', minHeight: '500px' }}>
                    {this.renderThead()}
                    {this.renderBody()}
                </table>
            );

        return (
            <table className="kt-datatable__table" style={{ overflow: 'inherit', minHeight: '500px' }}>
                {this.renderBodyMobie()}
            </table>
        );
    }
}

export default withRouter(List);