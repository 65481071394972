import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchOrders, setOrdersPaging, deleteOrder, setOrdersFilters } from '../../action/orders';
import { fetchCities, fetchFilials, fetchZonesByCities } from '../../action/handbook';
import Loader from '../utils/Loader';
import Portlet from '../utils/Portlet';
import ReactPaginate from 'react-paginate';
import OrdersTable from './OrdersTable';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';
import '../../styles/order_table.css'
import { fetchSettings } from '../../action/app';
import { roleId } from '../../constants/app';
import { setUIvisible } from '../../action/UImobile';
import { setActiveModalAction } from '../../action';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const getDateValue = (type) => {
    const date = new Date()
    switch(type){
        case 'toomorow':{
            return {Min: moment(date).add(1,'days').format('YYYY-MM-DD 00:00:00'), Max: moment(date).add(1,'days').format('YYYY-MM-DD 23:59:59')};
        }
        case 'yesterday':{
            return {Min: moment(date).subtract(1,'days').format('YYYY-MM-DD 00:00:00'), Max: moment(date).subtract(1,'days').format('YYYY-MM-DD 23:59:59')};
        }
        case 'week':{
            let monday = moment(date);
            monday.startOf('isoWeek'); 

            let sunday = moment(date);
            sunday.endOf('isoWeek'); 

            return {Min: monday.format('YYYY-MM-DD 00:00:00'), Max: sunday.format('YYYY-MM-DD 23:59:59')};
        }
        case 'month':{
            return {Min: moment(date).startOf('month').format('YYYY-MM-DD 00:00:00'), Max: moment(date).endOf('month').format('YYYY-MM-DD 23:59:59')};
        }
        default: {
            return {Min: moment(date).format('YYYY-MM-DD 00:00:00'), Max: moment(date).format('YYYY-MM-DD 23:59:59')};
        }
    }
}

const payments = [
    {label: 'Все виды', value: null},
    {label: 'Наличные', value: 'cash'},
    {label: 'Терминал', value: 'terminal'},
    {label: 'Оплачено', value: 'online'},
]

const statuses = [
    { value: null, label: 'Все статусы' },
    { value: 'new', label: 'Новый' },
    { value: 'processing', label: 'В обработке' },
    { value: 'not-confirmed', label: 'Не подтвержден' },
    { value: 'confirmed', label: 'Подтвержден' },
    { value: 'cook', label: 'Готовится' },
    { value: 'cooked', label: 'Приготовлен' },
    { value: 'delivery', label: 'В пути' },
    { value: 'done', label: 'Выполнен' },
    { value: 'written-off', label: 'Списан' },
    { value: 'cancel', label: 'Отменен' },
    { value: 'return', label: 'Возврат' },
    { value: 'deferred', label: 'Отложенный' }
]

class OrdersList extends Component {
    state = {
        currentPage: 0,
        selectedDate: null,
        openDateInput: false,
        dateValue: [
            {label: `Завтра ${moment(getDateValue('toomorow').Min).format('DD.MM.YYYY')}`, value: getDateValue('toomorow')},
            {label: `Сегодня ${moment(getDateValue('today').Min).format('DD.MM.YYYY')}`, value: getDateValue('today')},
            {label: `Вчера ${moment(getDateValue('yesterday').Min).format('DD.MM.YYYY')}`, value: getDateValue('yesterday')},
            {label: 'Текущая неделя', value: getDateValue('week')},
            {label: 'Текущий месяц', value: getDateValue('month')},
            {label: 'Период', value: 'period'}
        ]
    }

    DateInputRef = React.createRef();

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.filters !== prevState.filters) {
            const dateValue = [
                {label: `Завтра ${moment(getDateValue('toomorow').Min).format('DD.MM.YYYY')}`, value: getDateValue('toomorow')},
                {label: `Сегодня ${moment(getDateValue('today').Min).format('DD.MM.YYYY')}`, value: getDateValue('today')},
                {label: `Вчера ${moment(getDateValue('yesterday').Min).format('DD.MM.YYYY')}`, value: getDateValue('yesterday')},
                {label: 'Текущая неделя', value: getDateValue('week')},
                {label: 'Текущий месяц', value: getDateValue('month')},
                {label: `Период ${(prevState.selectedDate && prevState.selectedDate.value === 'period') ? moment(nextProps.filters.createdStartDate).format('DD.MM.YYYY') + ' - ' : ''}${(prevState.selectedDate && prevState.selectedDate.value === 'period') ? moment(nextProps.filters.createdEndDate).format('DD.MM.YYYY') : ''}`, value: 'period'}
            ];
            if(prevState.selectedDate && prevState.selectedDate.value === 'period') {
                return { dateValue, selectedDate: {
                    label: `Период ${moment(nextProps.filters.createdStartDate).format('DD.MM.YYYY')} - ${moment(nextProps.filters.createdEndDate).format('DD.MM.YYYY')}`,
                    value: 'period'
                } };
            }
            return { dateValue };
        }
        return null;
    }

    handleClickOutside = (event) => {
        if (this.DateInputRef.current && !this.DateInputRef.current.contains(event.target)) {
          this.setState({ openDateInput: false });
        }
      };

    componentDidMount() {
        const getData = async () => {
            await Promise.all([
                this.onChangeDateSelect(this.state.dateValue[1]),
                this.props.fetchOrders(this.props.isArchive),
                this.props.fetchCities(),
                this.props.fetchFilials(null),
                this.props.fetchSettings()      
            ]);

            this.props.fetchZonesByCities();
            //document.addEventListener('mousedown', this.handleClickOutside); handleClickOutside срабатывает при клике на календарь DateInput и закрывает окно с выбором периода
        }

        getData();
        this.props.setUIvisible(true, false);
    }    

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setOrdersPaging({ ...paging, start: 0 });
        //document.removeEventListener('mousedown', this.handleClickOutside); handleClickOutside срабатывает при клике на календарь DateInput и закрывает окно с выбором периода
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setOrdersPaging(updatedPaging);
        this.props.fetchOrders(this.props.isArchive);
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setOrdersPaging(updatedPaging);
            this.props.fetchOrders(this.props.isArchive);
        });
    };

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded order_table">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    setOrdersFilters = (field) => (data) => {
        const { filters, paging } = this.props;
        
        let updated = {
            ...filters,
            [field]: data
        }

        if(data.value === null) {
            updated = {
                ...filters,
                [field]: null
            }
        }

        this.props.setOrdersFilters(updated);
        this.props.setOrdersPaging({ ...paging, start: 0 });
        this.props.fetchOrders(this.props.isArchive);
        this.onSearch()
    }

    onChangeInput = (field) => (e) => {
        const value = e.target.value;

        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: value
        }
        this.props.setOrdersFilters(updated);
    }

    onChangeDateSelect = (e) => {
        const { filters } = this.props;

        const updated = {
            ...filters
        };

        if(e.value !== 'period') {
            updated.createdStartDate = moment(e.value.Min).toDate();
            updated.createdEndDate = moment(e.value.Max).toDate();        
            this.props.setOrdersFilters(updated);
            this.onSearch();    
            this.setState({ selectedDate: e });
        } else {
            this.setState({ openDateInput: true });
        }
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }
        
        this.props.setOrdersFilters(updated);
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setOrdersPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0, openDateInput: false });
        this.props.fetchOrders(this.props.isArchive);
    }

    resetFilter = () => {
        this.props.setOrdersFilters({ city: null, point: null, status: null, createdEndDate: null, createdStartDate: null });
        this.setState({ currentPage: 0 });
        this.props.fetchOrders(this.props.isArchive);
    }

    renderAdress(item) {
        return `${item.city.name}, ${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    renderSubHeader() {
        const { isArchive } = this.props;

        return (
                                     
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid" style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem', padding: '0px', paddingRight: '5px'} : {}}>{isArchive ? 'Архив заказов' : 'Заказы'}</h3>
                        <div className="kt-subheader__group" id="kt_subheader_search" style={window.innerWidth <= 1024 ? {display: 'block'} : {}}>
                            <span className="kt-subheader__desc" id="kt_subheader_total" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem'} : {}}>
                                {this.props.count}
                            </span>
                        </div>

                        {this.props.role === roleId.admin && 
                        <>
                            <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                            <h3 className="kt-subheader__title" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem', padding: '0px', paddingRight: '5px'} : {}}> Средний чек </h3>
                            <div className="kt-subheader__group" id="kt_subheader_search" style={window.innerWidth <= 1024 ? {display: 'block'} : {}}>
                                <span className="kt-subheader__desc" id="kt_subheader_total" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem'} : {}}>
                                    {(this.props.statistics.result && this.props.count > 0) ? parseFloat(this.props.statistics.result.sum / this.props.count).toFixed(2) : 'Ошибка'}
                                </span>
                            </div>
                            
                            <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                            <h3 className="kt-subheader__title" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem', padding: '0px', paddingRight: '5px'} : {}}> Выручка </h3>
                            <div className="kt-subheader__group" id="kt_subheader_search" style={window.innerWidth <= 1024 ? {display: 'block'} : {}}>
                                <span className="kt-subheader__desc" id="kt_subheader_total" style={window.innerWidth <= 1024 ? {fontSize: '0.8rem'} : {}}>
                                    { this.props.statistics.result ? this.props.statistics.result.sum : 'Ошибка'}
                                </span> 
                            </div>          
                        </>
                        }
                    </div>
                    {window.innerWidth > 1024 && 
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <button onClick={this.onOpenModalSearch} className='btn btn-secondary btn-icon'><i className="la la-search"></i></button>
                        <button onClick={this.onRefresh} className='btn btn-secondary btn-icon'><i className="la la-refresh"></i></button>
                    </div>
                    } 
                </div>                
            </div>
        );
    }

    onDelete = (id) => {
        swal({
            title: "Удаление заказа",
            text: `Вы действительно хотите удалить заказ #${id}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteOrder(id, this.props.isArchive).then(result => {
                    if (result) {
                        this.props.fetchOrders(this.props.isArchive);
                    }
                })
            }
        });
    }

    onRefresh = () => {
        this.props.fetchOrders(this.props.isArchive);
        this.props.fetchFilials(null);
        if(!this.props.settings)
            this.props.fetchSettings();
    }

    onOpenModalSearch = () => {
        this.props.setActiveModalAction({ field: 'orderSearch', value: true })
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onSearch(); 
        }
    }

    renderFiltersBlock(){
        if(window.innerWidth <= 900 && this.props.UImobile.openFilters)
            return(
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px', gap: '10px'}} onKeyDown={this.onKeyDown}>
                            <div style={{ width: 250 }}>
                                <Select
                                    isLoading={this.props.loadingCity || this.props.loadingFilials}
                                    isDisabled={this.props.loadingCity || this.props.loadingFilials}
                                    placeholder='Дата'
                                    value={this.state.selectedDate}
                                    onChange={(e) => this.onChangeDateSelect(e)}
                                    options={this.state.dateValue}
                                />
                                {this.state.openDateInput && <div style={{ position: 'relative' }}>
                                    <div ref={this.DateInputRef} style={{ position: 'absolute', zIndex: '1', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', color: '#000', marginTop: '10px' }}>
                                        <div>Период с</div>
                                        <DateInput                                            
                                            placeholderText="Дата от"
                                            selected={this.props.filters.createdStartDate}
                                            onChange={this.onChangeDateInput('createdStartDate')}
                                        />
                                        <div>по</div>
                                        <DateInput
                                            placeholderText="Дата до"
                                            selected={this.props.filters.createdEndDate}
                                            onChange={this.onChangeDateInput('createdEndDate')}
                                            maxDate={moment(this.props.filters.createdStartDate).add({ days: 30 }).toDate()}
                                            required={this.props.filters.createdStartDate}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                                            <button
                                            className='btn btn-secondary'
                                            onClick={(() => {
                                                this.setState({ openDateInput: false });
                                            })}
                                            >
                                                Закрыть
                                            </button>
                                            <button 
                                            className='btn btn-primary'
                                            onClick={(() => {
                                                this.setState({ selectedDate: this.state.dateValue[this.state.dateValue.length - 1] });
                                                this.onSearch();
                                            })}
                                            >
                                                Найти
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>              
                            <div style={{ width: 150 }}>
                                <Select
                                placeholder='Оплата'
                                isLoading={this.props.loadingCity || this.props.loadingFilials}
                                isDisabled={this.props.loadingCity || this.props.loadingFilials}
                                value={this.props.filters.payment}
                                onChange={this.setOrdersFilters('payment')}
                                options={payments}                        
                                />
                            </div>                
                            <div style={{ width: 250 }}>
                                <Select
                                isLoading={this.props.loadingFilials}
                                isDisabled={this.props.loadingFilials || this.props.filters.zone}
                                value={this.props.filters.point}
                                closeMenuOnSelect={true}
                                onChange={this.setOrdersFilters('point')}
                                options={[
                                    {value: null, label: 'Все филиалы'},
                                    ...this.props.filials.sort((a, b) => {
                                        const adressA = this.renderAdress(a);
                                        const adressB = this.renderAdress(b);
                                        if (adressA < adressB) return -1;
                                        if (adressA > adressB) return 1;
                                        return 0;
                                        })
                                        .map(el => ({
                                        value: el.id,
                                        label: this.renderAdress(el)
                                    }))
                                ]}
                                placeholder="Филиал"
                                noOptionsMessage={() => 'Нет филиалов'}
                                />
                            </div>
                            
                            <div style={{ width: 150 }}>
                                <Select
                                    value={this.props.filters.status}
                                    closeMenuOnSelect={true}
                                    onChange={this.setOrdersFilters('status')}
                                    menuPlacement="auto"
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                    }}
                                    options={statuses}                                     
                                    placeholder="Статус"
                                    noOptionsMessage={() => 'Статусы не найдены'}
                                />
                            </div>                                     
                </div>
        );

        if(window.innerWidth > 1024 || this.props.UImobile.openFilters)
            return(
                <table className="kt-datatable__table" onKeyDown={this.onKeyDown} style={{ backgroundColor: '#00000000', width: '100%' }}>
                    <td className='kt-indicator' style={{ backgroundColor: '#00000000' }}></td>
                    <td className={`kt-datatable__cell order_table positions${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>
                        <Select
                            isLoading={this.props.loadingCity || this.props.loadingFilials}
                            isDisabled={this.props.loadingCity || this.props.loadingFilials}
                            placeholder='Дата'
                            value={this.state.selectedDate}
                            onChange={(e) => this.onChangeDateSelect(e)}
                            options={this.state.dateValue}
                        />
                        {this.state.openDateInput && <div style={{ position: 'relative' }}>
                            <div ref={this.DateInputRef} style={{ position: 'absolute', zIndex: '1', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', color: '#000', marginTop: '10px' }}>
                                <div>Период с</div>
                                <DateInput                                                                      
                                    placeholderText="Дата от"
                                    selected={this.props.filters.createdStartDate}
                                    onChange={this.onChangeDateInput('createdStartDate')}
                                />
                                <div>по</div>
                                <DateInput                                    
                                    placeholderText="Дата до"
                                    selected={this.props.filters.createdEndDate}
                                    onChange={this.onChangeDateInput('createdEndDate')}
                                    maxDate={moment(this.props.filters.createdStartDate).add({ days: 30 }).toDate()}
                                    required={this.props.filters.createdStartDate}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                                    <button
                                    className='btn btn-secondary'
                                    onClick={(() => {
                                        this.setState({ openDateInput: false });
                                    })}
                                    >
                                        Закрыть
                                    </button>
                                    <button 
                                    className='btn btn-primary'
                                    onClick={(() => {
                                        this.setState({ selectedDate: this.state.dateValue[this.state.dateValue.length - 1] });
                                        this.onSearch();
                                    })}
                                    >
                                        Найти
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </td>
                    <td className={`kt-datatable__cell order_table total_price${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}></td>
                    <td className={`kt-datatable__cell order_table total_price${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}></td>
                    <td className={`kt-datatable__cell order_table payment${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>
                        <div className='order_table payment select'>
                            <Select
                            placeholder='Оплата'
                            isLoading={this.props.loadingCity || this.props.loadingFilials}
                            isDisabled={this.props.loadingCity || this.props.loadingFilials}
                            value={this.props.filters.payment}
                            onChange={this.setOrdersFilters('payment')}
                            options={payments}                        
                            />
                        </div>                        
                    </td>
                    <td className={`kt-datatable__cell order_table point_time${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}></td>
                    <td className={`kt-datatable__cell order_table select_point${(window.innerWidth > 1024 && this.state.openClient) ? ' openClient' : ''}${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>
                        <Select
                        isLoading={this.props.loadingFilials}
                        isDisabled={this.props.loadingFilials || this.props.filters.zone}
                        value={this.props.filters.point}
                        closeMenuOnSelect={true}
                        onChange={this.setOrdersFilters('point')}
                        options={[
                            {value: null, label: 'Все филиалы'},
                            ...this.props.filials.sort((a, b) => {
                                const adressA = this.renderAdress(a);
                                const adressB = this.renderAdress(b);
                                if (adressA < adressB) return -1;
                                if (adressA > adressB) return 1;
                                return 0;
                                })
                                .map(el => ({
                                value: el.id,
                                label: this.renderAdress(el)
                            }))
                        ]}
                        placeholder="Филиал"
                        noOptionsMessage={() => 'Нет филиалов'}
                        />
                    </td>                           
                    <td className={`kt-datatable__cell order_table comment${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}></td>
                    <td onDoubleClick={((event) => {event.stopPropagation()})} className='kt-datatable__cell order_table phone'>
                        <Select
                            value={this.props.filters.status}
                            closeMenuOnSelect={true}
                            onChange={this.setOrdersFilters('status')}
                            menuPlacement="auto"
                            styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 })
                            }}
                            options={statuses}                                     
                            placeholder="Статус"
                            noOptionsMessage={() => 'Статусы не найдены'}
                        />
                    </td>     
                    <td className='kt-datatable__cell order_table action'></td>
                </table>
            );        
    }

    render() {
        if (this.props.loading || this.props.loadingSettings) {
            return (
                <div style={styles.loading}><Loader /> </div>
            );
        }
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right kt-margin-b-10">
                            {window.innerWidth <= 1024 &&
                                <div style={{ position: 'fixed', top: '8px', right: '40px', zIndex: '10000', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <button onClick={this.onOpenModalSearch} className='head_mobile_btn'><i className="la la-search"></i></button>
                                    <button onClick={this.onRefresh} className='head_mobile_btn'><i className="la la-refresh"></i></button>
                                </div>
                            }
                            {this.renderFiltersBlock()}
                        </div>
                    </div>
                    <Portlet fit={true} style={{ backgroundColor: '#00000000'}}>
                        <ErrorContainer field="orders" style={{ margin: 15 }} hasCloseButton={true}/>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" style={{ marginBottom: '0px'}}>
                            <OrdersTable isArchive={this.props.isArchive} role={this.props.role} onDelete={this.onDelete} items={this.props.orders} filials={this.props.filials} filters={this.props.filters}/>
                            {
                                this.props.orders.length > 0 && this.renderPagination()
                            }
                        </div>
                    </Portlet>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    statistics: state.statistics.ordersOfHead,
    orders: state.orders.list,
    filters: state.orders.filters,
    loading: state.loaders.orders || state.loaders.ordersOfHead,
    count: state.orders.count,
    paging: state.orders.paging,
    cities: state.handbook.cities,
    filials: state.handbook.filials,
    role: state.user.info.role.id,
    settings: state.settings.init.categories,
    loadingFilials: state.loaders.filials,
    loadingCity: state.loaders.cities,
    loadingGoods: state.loaders.goods,
    loadingSettings: state.loaders.settings,
    goods: state.good.list,
    UImobile: state.UImobile
});

const mapDispatchToProps = {
    fetchOrders: (isArchive) => fetchOrders({}, isArchive),
    setOrdersPaging: (paging) => setOrdersPaging(paging),
    deleteOrder: (id, isArchive) => deleteOrder(id, isArchive),
    fetchCities: () => fetchCities(true),
    fetchFilials: (city) => fetchFilials(city, true),
    setOrdersFilters: (filters) => setOrdersFilters(filters),
    fetchSettings: () => fetchSettings(),
    fetchZonesByCities: () => fetchZonesByCities(),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton),
    setActiveModalAction: (value) => setActiveModalAction(value)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);